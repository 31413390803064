import config from '../config.js'
import { Connection, PublicKey, LAMPORTS_PER_SOL, SystemProgram, Transaction } from "@solana/web3.js";

export default class Sol {

    constructor(chain) {
        this.user = null;
        this.provider = null;
        this.setChain(chain);
    }

    getUsername() {
        if (!this.user) {
            return null;
        }
        return this.user.toString();
    }

    async setChain(chain) {
        this.chain = chain;
        this.connection = new Connection(config[chain].apiUri);
    }

    async login(wallet) {
        const objRet={success:true};
        try {
            if (this.user) {
                await this.logout();
            }

            if (wallet === 'phantom') {
                if (window.phantom && window.phantom.solana && window.phantom.solana.isPhantom) {
                    this.provider = window.phantom.solana;
                    try {
                        const resp = await this.provider.connect();
                        if (resp) {
                            this.user = resp.publicKey;
                        }
                        else{
                            objRet.success=false;
                            objRet.error='Wallet connect error';
                        }
                    } catch (e) {
                        objRet.success=false;
                        objRet.error='message: '+e.message;
                        // { code: 4001, message: 'User rejected the request.' }
                    }
                }
                else{
                    objRet.success=false;
                    objRet.error='Phantom wallet not found.';
                }
            } else if (wallet === 'solflare') {
                if (window.solflare && window.solflare.isSolflare) {
                    this.provider = window.solflare;
                    try {
                        const resp = await this.provider.connect();
                        if (resp) {
                            this.user = this.provider.publicKey;
                        }
                        else{
                            objRet.success=false;
                            objRet.error='Wallet connect error';
                        }
                    } catch (e) {
                        objRet.success=false;
                        objRet.error='message: '+e.message;
                    }
                }
                else{
                    objRet.success=false;
                    objRet.error='Solflare wallet not found.';
                }
            }
        }
        catch (e) {
            objRet.success=false;
            objRet.error='message: '+e.message+ '\nstack: '+ e.stack;
        }
        return objRet;
    }

    async logout() {
        if(this.user) {
            await this.provider.disconnect();
            this.provider = null;
            this.user = null;
        }
    }

    async makePurchase(purchaseId, totalPrice) {
        let transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: this.user,
                toPubkey: new PublicKey(config[this.chain].contract),
                lamports: totalPrice * LAMPORTS_PER_SOL,
            })
        );

        return await this.signAndSendTransaction(transaction);
    };

    async signAndSendTransaction(transaction) {
        let { blockhash } = await this.connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = this.user;
        let signed = await this.provider.signTransaction(transaction);
        let txid = await this.connection.sendRawTransaction(signed.serialize());
        return txid;
    }

    async getWalletBalance() {
        if(this.user) {
            let balance = await this.connection.getBalance(this.user);
            balance /= LAMPORTS_PER_SOL;
            return balance;
        }
        return 0;
    }
}

import config from '../config.js'
import { parseEther, formatEther } from '@ethersproject/units';

export default class Eth {

    constructor(chain) {
        this.user = null;
        this.setChain(chain);
    }

    getUsername() {
        if (!this.user) {
            return null;
        }
        return this.user.toString();
    }

    async setChain(chain) {
        this.chain = chain;
    }

    async login(wallet) {
        const objRet={success:true};
        try {
            if (this.user) {
                await this.logout();
            }

            if (wallet === 'metamask') {
                if (window.ethereum && window.ethereum.isMetaMask) {
                    this.provider = window.ethereum;
                    try {
                        // Request account access if needed
                        const approvedAddresses = await this.provider.request({
                            method: "eth_requestAccounts"
                        });
                        this.user = approvedAddresses[0];

                        // Optionally switch to the correct network (Polygon/POL or BSC)
                        const objSw=await this.switchNetwork();
                        if (objSw.success===false){
                            objRet.success=false;
                            objRet.error=objSw.error;
                        }

                    } catch (e) {
                        objRet.success=false;
                        objRet.error='message: '+e.message;
                        console.error("User has rejected the request or there was an error.");
                    }
                } else {
                    objRet.success=false;
                    objRet.error='MetaMask not detected.';
                    console.error(objRet.error);
                }
            }
        }
        catch (e) {
            objRet.success=false;
            objRet.error='message: '+e.message+ '\nstack: '+ e.stack;
        }
        return objRet;
    }

    async logout() {
        if(this.user) {
            this.provider = null;
            this.user = null;
        }
    }

    async makePurchase(purchaseId, totalPrice) {
        try {
            await this.switchNetwork();
            let tx = await this.provider.request({
              method: "eth_sendTransaction",
              params: [{
                to: config[this.chain].contract, // The contract address from config
                from: this.user,
                value: parseEther(totalPrice.toString())._hex
                }],
            });

            return tx;
        } catch (error) {
            console.error(error);
        }
    }

    async getWalletBalance() {
        if (this.user) {
            try {
                let balance = await this.provider.request({
                    method: 'eth_getBalance',
                    params: [this.user, 'latest']  // Get balance at latest block
                });
                balance = formatEther(balance);
                return +balance;
            } catch (error) {
                console.error("Error fetching balance: ", error);
                return 0;
            }
        }
        return 0;
    }

    async switchNetwork() {
        const objRet={success:true};
        // Detect current network and switch if needed
        const chainId = this.chain === 'POL' ? '0x89' : '0x38'; // 0x89 (137) for Polygon (POL), 0x38 for BSC

        try {
            // Check if the user is already on the correct network
            const currentChainId = await this.provider.request({ method: 'eth_chainId' });

            if (currentChainId !== chainId) {
                // Request to switch to the correct network
                await this.provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: chainId }],
                });
            }
        } catch (switchError) {
            // Handle errors (e.g., the network is not added in MetaMask)
            console.error("Failed to switch network:", switchError);

            if (switchError.code === 4902) {
                // If the network is not available, suggest the user adds it
                try {
                    const params = this.chain === 'POL' ? {
                        chainId: '0x89',  // Polygon Mainnet (137) with POL upgrade
                        chainName: 'Polygon Mainnet',
                        nativeCurrency: { name: 'POL', symbol: 'POL', decimals: 18 },
                        rpcUrls: ['https://polygon-mainnet.infura.io'],
                        blockExplorerUrls: ['https://polygonscan.com/'],
                    } : {
                        chainId: '0x38',  // BSC Mainnet
                        chainName: 'BNB Smart Chain Mainnet',
                        nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
                        rpcUrls: ['https://bsc-dataseed.binance.org/'],
                        blockExplorerUrls: ['https://bscscan.com/'],
                    };

                    await this.provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [params],
                    });
                } catch (addError) {
                    console.error("Failed to add network:", addError);
                    objRet.success=false;
                    objRet.error="Failed to add network: "+addError.message;
                }
            }
            else{
                objRet.success=false;
                objRet.error="Failed to switch network: "+switchError.message;
            }
        }
        return objRet;
    }
}

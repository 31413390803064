
import config from '../config.js';
import Eos from './eos.js';
import Sol from './sol.js';
import Ronin from './ronin.js';
import Eth from './eth.js';

export default class Wallet {

    constructor(chain) {
        this.chain = null
        this.tonWallet = null;
        this.setChain(chain);

        this.waxWallet = new Eos("WAX");
        this.solWallet = new Sol("SOL");
        this.roninWallet = new Ronin("RON");
        this.polWallet = new Eth("POL");
        this.bnbWallet = new Eth("BNB");
        this.createTon();
    }

    createTon(){
        try{
            const ts=this;

            const src_ton = document.createElement('script');
            //const srcUrl=Utils.getGlobalLink('tonconnect-sdk.min.js');
            let srcUrl='https://blockspingaming.com/tonscript/pay.js';
            let url = window.location.hostname;
            if(url.includes("test.")){
                srcUrl='https://test.blockspingaming.com/tonscript/pay.js';
            }
            if (url.includes("localhost")){
                srcUrl='http://localhost:8080//tonscript/pay.js';
            }
            src_ton.setAttribute('src', srcUrl);
            document.head.appendChild(src_ton);

            src_ton.onload = ()=>{
                ts.tonWallet = new myTon(config.TON.contract);
            };
        }
        catch (e) {
            console.log('Create Ton catch: '+ e.message + '\nstack: ' + e.stack)
        }
    }

    setChain(chain) {
        if (chain === this.chain) {
            return;
        }
        this.chain = chain;
        if(config[chain]) {
            switch(chain) {
                case "WAX": {
                    this.chainWallet = this.waxWallet;
                    break;
                }
                case "SOL": {
                    this.chainWallet = this.solWallet;
                    break;
                }
                case "RON": {
                    this.chainWallet = this.roninWallet;
                    break;
                }
                case "POL": {
                    this.chainWallet = this.polWallet;
                    break;
                }
                case "BNB": {
                    this.chainWallet = this.bnbWallet;
                    break;
                }
                case "TON": {
                    this.chainWallet = this.tonWallet;
                    break;
                }
            }
        }
    }

    getUsername() {
        if(this.chainWallet) {
            return this.chainWallet.getUsername();
        }
        return null;
    }

    async login(wallet) {
        return this.chainWallet.login(wallet);
    }

    async logout() {
        return this.chainWallet.logout();
    }

    async makePurchase(purchaseId, totalPrice) {
        return this.chainWallet.makePurchase(purchaseId, totalPrice);
    };

    async getWalletBalance(accountName) {
        return this.chainWallet.getWalletBalance(accountName);
    }

}

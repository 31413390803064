import config from '../config.js'
import { parseEther, formatEther } from '@ethersproject/units';

export default class Ronin {

    constructor(chain) {
        this.user = null;
        this.setChain(chain);
    }

    getUsername() {
        if (!this.user) {
            return null;
        }
        return this.user.toString();
    }

    async setChain(chain) {
        this.chain = chain;
    }

    async login(wallet) {
        if (this.user) {
            await this.logout();
        }

        if(wallet == 'ronin') {
            if(window.ronin) {
                this.provider = window.ronin.provider;
                try {
                    const approvedAddresses = await this.provider.request({ 
                      method: "eth_requestAccounts" 
                    })
                    this.user = approvedAddresses[0];
                  } catch (error) {
                    //alert("User has rejected the request.")
                  }
            }
        }
    }

    async logout() {
        if(this.user) {
            this.provider = null;
            this.user = null;
        }
    }

    async makePurchase(purchaseId, totalPrice) {
        try {
            let tx = await this.provider.request({
              method: "eth_sendTransaction",
              params: [{
                to: config[this.chain].contract,
                from: this.user,
                value: parseEther(totalPrice.toString())._hex,
                gas: "0x5208",
                }],
            });

            return tx;
        } catch (error) {
            console.error(error)
        }
    };

    async getWalletBalance() {
        if(this.user) {
            const response = await fetch(config[this.chain].apiUri + 'skynet/ronin/accounts/' + this.user, {
                method: 'get',
                headers: {
                    "Content-type": "application/json",
                    'X-API-KEY': '1MADpb11ThTsVCdgIx6tgrTdJxCnyGGT'
                },
            });
            let account = await response.json();

            let balance = account.result.balance;
            balance = formatEther(balance);
            return +balance;
        }
        return 0;
    }
}